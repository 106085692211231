.custom-tooltip {
  position: absolute;
  width: 300px;
  height: 200px;
  // border: 0.01px solid #e2e2e2;
  overflow: hidden;
  pointer-events: none;
  transition: opacity 0.1s;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.custom-tooltip.ag-tooltip-hiding {
  opacity: 0;
}

.custom-tooltip p {
  margin: 10px;
  font-size: 16px;
  // white-space: nowrap;
  word-wrap: break-word;
}

.custom-tooltip p:first-of-type {
  // font-weight: bold;
}
