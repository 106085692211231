.btn-file {
  position: relative;
  overflow: hidden;
  vertical-align: middle;
}
.btn-file > input {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 23px;
  cursor: pointer;
  filter: alpha(opacity=0);
  opacity: 0;

  direction: ltr;
}
.fileinput {
  &.text-center {
    text-align: center;
  }
  & .text-center {
    text-align: center;
  }
  .fa {
    font-size: 14px;
    margin-top: -6px;
    position: relative;
    top: 2px;
    margin-right: 4px;
  }
  display: inline-block;
  margin-bottom: 9px;

  input[type="file"] {
    display: none;
  }
}
.fileinput .form-control {
  display: inline-block;
  padding-top: 7px;
  padding-bottom: 5px;
  margin-bottom: 0;
  vertical-align: middle;
  cursor: text;
}
.fileinput .thumbnail {
  display: inline-block;
  margin-bottom: 10px;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  max-width: 250px;
  @include shadow-big();

  &.mini {
    margin-right: 10px;
    position: relative;
    text-align: left;
  }

  &.img-circle {
    border-radius: 50%;
    max-width: 100px;
  }
}

.fileinput .thumbnail > img {
  max-height: 100%;
  width: 100%;
  height: auto;
  margin-right: auto;
  margin-left: auto;
  display: block;
  max-width: 100%;
}

.fileinput a {
  text-decoration: none;
  color: black;
  cursor: pointer;
  font-weight: 400;
}
.fileinput .thumbnail div.desc{
  text-align: center;
}
.fileinput .mini img {
  width: 100px;
  height: 100px;
}
.fileinput .mini button {
  position: absolute;
  right: 0;
  padding: 5px;
}

.fileinput .btn {
  vertical-align: middle;
}
.fileinput-exists .fileinput-new,
.fileinput-new .fileinput-exists {
  display: none;
}
.fileinput-inline .fileinput-controls {
  display: inline;
}
.fileinput-filename {
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
}
.form-control .fileinput-filename {
  vertical-align: bottom;
}
.fileinput.input-group {
  display: table;
}
.fileinput.input-group > * {
  position: relative;
  z-index: 2;
}
.fileinput.input-group > .btn-file {
  z-index: 1;
}
.fileinput-new.input-group .btn-file,
.fileinput-new .input-group .btn-file {
  border-radius: 0 4px 4px 0;
}
.fileinput-new.input-group .btn-file.btn-xs,
.fileinput-new .input-group .btn-file.btn-xs,
.fileinput-new.input-group .btn-file.btn-sm,
.fileinput-new .input-group .btn-file.btn-sm {
  border-radius: 0 3px 3px 0;
}
.fileinput-new.input-group .btn-file.btn-lg,
.fileinput-new .input-group .btn-file.btn-lg {
  border-radius: 0 6px 6px 0;
}
.form-group.has-warning .fileinput .fileinput-preview {
  color: $brand-warning;
}
.form-group.has-warning .fileinput .thumbnail {
  border-color: $brand-warning;
}
.form-group.has-error .fileinput .fileinput-preview {
  color: $brand-danger;
}
.form-group.has-error .fileinput .thumbnail {
  border-color: $brand-danger;
}
.form-group.has-success .fileinput .fileinput-preview {
  color: $brand-success;
}
.form-group.has-success .fileinput .thumbnail {
  border-color: $brand-success;
}
.input-group-addon:not(:first-child) {
  border-left: 0;
}
.thumbnail {
  border: 0 none;
  border-radius: 4px;
  padding: 0;
}
.picture-container {
  position: relative;
  cursor: pointer;
  text-align: center;
  .picture {
    width: 106px;
    height: 106px;
    background-color: #999999;
    border: 4px solid #cccccc;
    color: #ffffff;
    border-radius: 50%;
    margin: 5px auto;
    overflow: hidden;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;

    &:hover {
      border-color: $pink;
    }

    input[type="file"] {
      cursor: pointer;
      display: block;
      height: 100%;
      left: 0;
      opacity: 0 !important;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
  .picture-src {
    width: 100%;
  }
}
